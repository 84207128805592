@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/calendar';




.grey-container {
  width: 600px;
  margin: 10px auto;
  background-color: lightgrey;
  padding: 10px;
  border-radius: 10px;
  text-align: center;

  h2 {
    font-size: 64px;
    padding: 10px 0;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.holiday {
  color: red;
}

.current {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    border: 2px solid red;
    background-color: lightgrey;
    width: 30px;
    height:  30px;
    z-index: -1;
    top: -3px;
    left: -5px;
  }
  //span {
  //  background-color: lightgrey;
  //}
}
.next, .prev {
  opacity: 0.3;
}

.week-name.dow {
  &:nth-last-child(-n + 2) {
    color:red !important;
  }
}

.week-name:not(.dow) {
  &:nth-child(-n + 2),
  &:last-child{
    color:red !important;
  }
  color: black;
}

.calendar {
	justify-items: center;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(4, 1fr);
	grid-column-gap: 3px;
	grid-row-gap: 3px;

  table {
    width: unset;
    td {
      width: 40px;
      height: unset;
    }
    tr:first-child {
      font-weight: bold;
    }
  }
}